import AddressHeader from "./Address";
import Col from "react-bootstrap/Col";
import LanguageSwitcher from "../buttons/LanguageSwitcher";
import React from "react";
import Row from "react-bootstrap/Row";
import Progress from "./ProgressBar";

function ContentHeader (props) {
    const hideLangSwitcher = (props.activeTab !== "homepage") && (process.env.NODE_ENV !== "development");
    const progress = props.progress || 0;
    return (
        <div className="ContentHeader">
            <Row>
                <Col>
                    <AddressHeader hotel={props.hotel}/>
                </Col>
                <Col>
                    <Progress progress={progress}/>
                </Col>
                <Col className="text-md-end">
                    <LanguageSwitcher hide={hideLangSwitcher}/>
                </Col>
            </Row>
        </div>
    );
}

export default ContentHeader;
