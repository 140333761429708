import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useHotelinfoData, useUpdateHotelinfoData } from "../contextProvider/HotelinfoContext";

export function Checkbox (props) {
    const hotelinfoData = useHotelinfoData();
    const updateHotelinfoData = useUpdateHotelinfoData();
    const { i18n } = useTranslation();
    const key = props.key ? props.key : props.label;
    const disabled = !!props.disabled;
    const [checked, setChecked] = useState(Boolean(props.initialValue));
    const { onCallback } = props;

    useEffect(() => {
        if (disabled) {
            setChecked(false);
            updateValue(key, false);
        }
    }, [disabled]);

    function updateValue (name, checked) {
        const keyArray = name.split("_");
        if (keyArray.length) {
            if (keyArray.length === 2) {
                hotelinfoData.hotelinfo[keyArray[0]][keyArray[1]] = checked;
            } else if (keyArray.length === 3) {
                hotelinfoData.hotelinfo[keyArray[0]][keyArray[1]][keyArray[2]] = checked;
            }
            updateHotelinfoData(hotelinfoData);
        }
    }

    function handleChange (event) {
        const { name, checked } = event.target;
        setChecked(checked);
        updateValue(name, checked);
        if (onCallback) {
            onCallback(checked);
        }
    }

    return (
        <Form.Check
            type="checkbox"
            key={key}
            id={key}
            name={key}
            label={i18n.t(props.label)}
            onChange={handleChange}
            disabled={disabled}
            checked={checked}/>
    );
}
