import React, { useEffect, useState } from "react";
import { FloatingLabel } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useHotelinfoData, useUpdateHotelinfoData } from "../contextProvider/HotelinfoContext";

export function Input (props) {
    const { i18n } = useTranslation();
    const hotelinfoData = useHotelinfoData();
    const updateHotelinfoData = useUpdateHotelinfoData();
    const [val, setVal] = useState(props.initialValue === null ? "" : props.initialValue);
    const { onCallback } = props;
    const placeholder = props.placeholder ? props.placeholder : props.label;
    const controlId = props.controlId ? props.controlId : props.label;
    const type = props.type ? props.type : "text";
    const disabled = props.disabled || false;
    const required = props.required || false;
    let min = "";
    let max = "";
    if (type === "number") {
        min = props.min ? props.min : 0;
        max = props.max ? props.max : 9999;
    }

    function updateValue (name, value) {
        const keyArray = name.split("_");
        if (keyArray.length) {
            if (keyArray.length === 2) {
                hotelinfoData.hotelinfo[keyArray[0]][keyArray[1]] = value;
            } else if (keyArray.length === 3) {
                if ((keyArray[2] === "von") || (keyArray[2] === "bis")) {
                    const stunde = Number(value.split(":")[0]);
                    const minute = Number(value.split(":")[1]);
                    hotelinfoData.hotelinfo[keyArray[0]][keyArray[1]][keyArray[2]] = { stunde, minute };
                } else {
                    hotelinfoData.hotelinfo[keyArray[0]][keyArray[1]][keyArray[2]] = value;
                }
            } else if (keyArray.length === 4) {
                hotelinfoData.hotelinfo[keyArray[0]][keyArray[1]][keyArray[2]][keyArray[3]] = value;
            }
            updateHotelinfoData(hotelinfoData);
        }
    }

    function handleChange (event) {
        const { name, value } = event.target;
        setVal(value);
        updateValue(name, value);
        if (onCallback) {
            onCallback(value);
        }
    }

    useEffect(() => {
        if (disabled) {
            setVal("");
            updateValue(controlId, "");
        }
    }, [disabled]);

    const label = i18n.t(props.label) + (required ? " *" : "");

    return (
        <FloatingLabel
            controlId={controlId}
            label={label}>
            <Form.Control
                name={controlId}
                disabled={disabled}
                required={!!required}
                type={type}
                min={min}
                max={max}
                placeholder={i18n.t(placeholder)}
                onChange={handleChange}
                onKeyUp={props.onKeyUp}
                value={val}/>
            {
                required
                    ? <Form.Control.Feedback type="invalid">
                        {i18n.t("validierung_" + controlId)}
                    </Form.Control.Feedback>
                    : ""
            }
        </FloatingLabel>
    );
}
