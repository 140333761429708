import options from "./options/gastronomie";
import React, { useState } from "react";
import AlertMessage from "../elements/AlertMessage";
import { SaveAndContinue } from "../elements/buttons/SaveAndContinue";
import { Checkbox } from "../inputfields/Checkbox";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { H4 } from "../elements/H4";
import { H5 } from "../elements/H5";
import { Input } from "../inputfields/Input";
import Row from "react-bootstrap/Row";
import { SelectBox } from "../inputfields/SelectBox";
import { useHotelinfoData } from "../contextProvider/HotelinfoContext";
import { useTabContext, useUpdateTabData } from "../contextProvider/TabContext";

export function Gastronomie (props) {
    const hotelinfoData = useHotelinfoData();
    const tabContext = useTabContext();
    const updateTabContextData = useUpdateTabData();
    const [validated, setValidated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, process.env.REACT_APP_ALERT_TIMEOUT);
        } else {
            setShowAlert(false);
            tabContext.validation.gastronomie = true;
            updateTabContextData(tabContext);
            props.handleSaveAndContinue("hotelinfo_freizeit");
        }
        setValidated(true);
    };
    const gastronomie = hotelinfoData.hotelinfo.gastronomie;
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <H4 label={props.category}/>
            <Row className="InputPadding">
                <Col md={3}>
                    <Input required={true} label="gastronomie_anzahlRestaurants" type="number"
                        initialValue={gastronomie.anzahlRestaurants}/>
                </Col>
                <Col md={3}>
                    <Input required={true} label="gastronomie_anzahlSitzplaetze" type="number"
                        initialValue={gastronomie.anzahlSitzplaetze}/>
                </Col>
                <Col md={2}>
                    <Checkbox label="gastronomie_aussenbewirtschaftungMoeglich"
                        initialValue={gastronomie.aussenbewirtschaftungMoeglich}/>
                </Col>
                <Col md={2}>
                    <Checkbox label="gastronomie_cafeBistroVorhanden"
                        initialValue={gastronomie.cafeBistroVorhanden}/>
                </Col>
                <Col md={2}>
                    <Checkbox label="gastronomie_barVorhanden"
                        initialValue={gastronomie.barVorhanden}/>
                </Col>
            </Row>
            <H5 label="gastronomie_fruehstueck"/>
            <Row className="InputPadding">
                <Col md={3}>
                    <SelectBox label="gastronomie_fruehstueckOrt"
                        options={options.getFruehstueckOrtArray()}
                        initialValue={gastronomie.fruehstueckOrt}/>
                </Col>
                <Col md={3}>
                    <SelectBox label="gastronomie_fruehstueckGruppen"
                        name="gastronomie_fruehstueck"
                        options={options.getFruehstueckGruppenBekommenArray()}
                        initialValue={gastronomie.fruehstueck}/>
                </Col>
                <Col md={4}>
                    <Checkbox label="gastronomie_fruehstueckWieIndividualgaeste"
                        initialValue={gastronomie.fruehstueckWieIndividualgaeste}/>
                </Col>
            </Row>
            <Row className="InputPadding">
                <Col>
                    <H5 label="gastronomie_fruehstueckszeiten"/>
                    <Row className="InputPadding">
                        <Col>
                            <Input label="gastronomie_fruehstueckszeiten_von"
                                type="time" required={true}
                                initialValue={getTime(gastronomie.fruehstueckszeiten.von)}/>
                        </Col>
                        <Col>
                            <Input label="gastronomie_fruehstueckszeiten_bis"
                                type="time" required={true}
                                initialValue={getTime(gastronomie.fruehstueckszeiten.bis)}/>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <H5 label="gastronomie_abweichendeFruehstueckszeitenAmWochenende"/>
                    <Row className="InputPadding">
                        <Col>
                            <Input label="gastronomie_abweichendeFruehstueckszeitenAmWochenende_von" type="time"
                                initialValue={getTime(gastronomie.abweichendeFruehstueckszeitenAmWochenende.von)}/>
                        </Col>
                        <Col>
                            <Input label="gastronomie_abweichendeFruehstueckszeitenAmWochenende_bis" type="time"
                                initialValue={getTime(gastronomie.abweichendeFruehstueckszeitenAmWochenende.bis)}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <H5 label="gastronomie_abendessen"/>
            <Row className="InputPadding">
                <Col md={3}>
                    <SelectBox label="gastronomie_abendessenOrt"
                        options={options.getAbendessenOrtArray()}
                        initialValue={gastronomie.abendessenOrt}/>
                </Col>
                <Col md={3}>
                    <SelectBox label="gastronomie_abendessenGruppen"
                        options={options.getAbendessenGruppenBekommenArray()}
                        initialValue={gastronomie.abendessenGruppen}/>
                </Col>
                <Col md={2}>
                    <Checkbox label="gastronomie_abendessenAlsGruppe"
                        initialValue={gastronomie.abendessenAlsGruppe}/>
                </Col>
                <Col md={2}>
                    <Checkbox label="gastronomie_abendessenHauptgangZurWahl"
                        initialValue={gastronomie.abendessenHauptgangZurWahl}/>
                </Col>
                <Col md={2}>
                    <Checkbox label="gastronomie_abendessenSalatbuffet"
                        initialValue={gastronomie.abendessenSalatbuffet}/>
                </Col>
            </Row>
            <Row className="InputPadding">
                <Col>
                    <H5 label="gastronomie_abendessenzeiten"/>
                    <Row className="InputPadding">
                        <Col>
                            <Input label="gastronomie_abendessenzeiten_von"
                                type="time" required={true}
                                initialValue={getTime(gastronomie.abendessenzeiten.von)}/>
                        </Col>
                        <Col>
                            <Input label="gastronomie_abendessenzeiten_bis"
                                type="time" required={true}
                                initialValue={getTime(gastronomie.abendessenzeiten.bis)}/>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <H5 label="gastronomie_barOeffnungszeiten"/>
                    <Row className="InputPadding">
                        <Col>
                            <Input label="gastronomie_barOeffnungszeiten_von" type="time"
                                initialValue={getTime(gastronomie.barOeffnungszeiten.von)}/>
                        </Col>
                        <Col>
                            <Input label="gastronomie_barOeffnungszeiten_bis" type="time"
                                initialValue={getTime(gastronomie.barOeffnungszeiten.bis)}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <SaveAndContinue />
            <AlertMessage show={showAlert}/>
        </Form>
    );
}

function getTime (timeObj) {
    if ((timeObj.stunde === null) || (timeObj.minute === null)) return "";
    const stunde = timeObj.stunde > 9 ? timeObj.stunde : "0" + timeObj.stunde;
    const minute = timeObj.minute > 9 ? timeObj.minute : "0" + timeObj.minute;
    return `${stunde}:${minute}`;
}
