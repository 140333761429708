import I18NextHttpBackend from "i18next-http-backend";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next
    .use(initReactI18next)
    .use(I18NextHttpBackend)
    .init({
        defaultLocale: "de",
        fallbackLng: "de",
        locales: ["de", "en", "fr"],
        localeDetection: false
    });

export default i18next;
