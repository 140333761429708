import React from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

export function SaveAndContinue (props) {
    const { i18n } = useTranslation();
    return <Button className="Button" variant="outline-success" type="submit">
        {i18n.t("button_sichernUndWeiter")}
    </Button>;
}
