
function handleIncomingHotelinfoData (hotelinfoData) {
    hotelinfoData.hotelinfo.allgemein.lobby = hotelinfoData.hotelinfo.allgemein.lobby ||
        "mittel";
    hotelinfoData.hotelinfo.allgemein.reception = hotelinfoData.hotelinfo.allgemein.reception ||
        "vollzeit";
    hotelinfoData.hotelinfo.anreise.checkIn.checkIn = hotelinfoData.hotelinfo.anreise.checkIn.checkIn ||
        "reiseleiterProGruppe";
    hotelinfoData.hotelinfo.anreise.checkIn.kreditkarte = hotelinfoData.hotelinfo.anreise.checkIn.kreditkarte ||
        "reiseleiterProGruppe";
    hotelinfoData.hotelinfo.anreise.checkIn.ausweis = hotelinfoData.hotelinfo.anreise.checkIn.ausweis ||
        "reiseleiterProGruppe";
    hotelinfoData.hotelinfo.gastronomie.fruehstueckOrt = hotelinfoData.hotelinfo.gastronomie.fruehstueckOrt ||
        "imKonferenzraum";
    hotelinfoData.hotelinfo.gastronomie.abendessenGruppen = hotelinfoData.hotelinfo.gastronomie.abendessenGruppen ||
        "buffet";
    hotelinfoData.hotelinfo.gastronomie.abendessenOrt = hotelinfoData.hotelinfo.gastronomie.abendessenOrt ||
        "imKonferenzraum";
    if (!hotelinfoData.hotelinfo.freizeit.wellnessbereich.wellnessbereichVorhanden) {
        hotelinfoData.hotelinfo.freizeit.wellnessbereich.nutzungGegenGebuehr = false;
    }
    if (!hotelinfoData.hotelinfo.freizeit.fitnessbereich.fitnessbereichVorhanden) {
        hotelinfoData.hotelinfo.freizeit.fitnessbereich.nutzungGegenGebuehr = false;
    }
    if (!hotelinfoData.hotelinfo.allgemein.letzteRenovierung) {
        Object.assign(hotelinfoData.hotelinfo.allgemein, {
            letzteRenovierung: {
                monat: "",
                jahr: ""
            }
        });
    }
    if (!hotelinfoData.hotelinfo.allgemein.renovierungBis) {
        Object.assign(hotelinfoData.hotelinfo.allgemein, {
            renovierungBis: {
                monat: "",
                jahr: ""
            }
        });
    }
    if (!hotelinfoData.hotelinfo.gastronomie.fruehstueckszeiten) {
        Object.assign(hotelinfoData.hotelinfo.gastronomie, {
            fruehstueckszeiten: {}
        });
    }
    if (!hotelinfoData.hotelinfo.gastronomie.fruehstueckszeiten.von) {
        Object.assign(hotelinfoData.hotelinfo.gastronomie.fruehstueckszeiten, {
            von: {
                stunde: null,
                minute: null
            }
        });
    }
    if (!hotelinfoData.hotelinfo.gastronomie.fruehstueckszeiten.bis) {
        Object.assign(hotelinfoData.hotelinfo.gastronomie.fruehstueckszeiten, {
            bis: {
                stunde: null,
                minute: null
            }
        });
    }
    if (!hotelinfoData.hotelinfo.gastronomie.abweichendeFruehstueckszeitenAmWochenende) {
        Object.assign(hotelinfoData.hotelinfo.gastronomie, {
            abweichendeFruehstueckszeitenAmWochenende: {}
        });
    }
    if (!hotelinfoData.hotelinfo.gastronomie.abweichendeFruehstueckszeitenAmWochenende.von) {
        Object.assign(hotelinfoData.hotelinfo.gastronomie.abweichendeFruehstueckszeitenAmWochenende, {
            von: {
                stunde: null,
                minute: null
            }
        });
    }
    if (!hotelinfoData.hotelinfo.gastronomie.abweichendeFruehstueckszeitenAmWochenende.bis) {
        Object.assign(hotelinfoData.hotelinfo.gastronomie.abweichendeFruehstueckszeitenAmWochenende, {
            bis: {
                stunde: null,
                minute: null
            }
        });
    }
    if (!hotelinfoData.hotelinfo.gastronomie.abendessenzeiten) {
        Object.assign(hotelinfoData.hotelinfo.gastronomie, {
            abendessenzeiten: {}
        });
    }
    if (!hotelinfoData.hotelinfo.gastronomie.abendessenzeiten.von) {
        Object.assign(hotelinfoData.hotelinfo.gastronomie.abendessenzeiten, {
            von: {
                stunde: null,
                minute: null
            }
        });
    }
    if (!hotelinfoData.hotelinfo.gastronomie.abendessenzeiten.bis) {
        Object.assign(hotelinfoData.hotelinfo.gastronomie.abendessenzeiten, {
            bis: {
                stunde: null,
                minute: null
            }
        });
    }
    if (!hotelinfoData.hotelinfo.gastronomie.barOeffnungszeiten) {
        Object.assign(hotelinfoData.hotelinfo.gastronomie, {
            barOeffnungszeiten: {}
        });
    }
    if (!hotelinfoData.hotelinfo.gastronomie.barOeffnungszeiten.von) {
        Object.assign(hotelinfoData.hotelinfo.gastronomie.barOeffnungszeiten, {
            von: {
                stunde: null,
                minute: null
            }
        });
    }
    if (!hotelinfoData.hotelinfo.gastronomie.barOeffnungszeiten.bis) {
        Object.assign(hotelinfoData.hotelinfo.gastronomie.barOeffnungszeiten, {
            bis: {
                stunde: null,
                minute: null
            }
        });
    }
    if (!hotelinfoData.hotelinfo.bearbeiter) {
        Object.assign(hotelinfoData.hotelinfo, {
            bearbeiter: {
                vorname: "",
                nachname: "",
                email: ""
            }
        });
    }
    return hotelinfoData;
}

function handleOutgoingHotelinfoData (hotelinfoData) {
    // Anreise Sehenswürdigkeiten
    if (!hotelinfoData.hotelinfo.anreise.sehenswuerdigkeit1.dauerZuFussInMinuten) {
        delete hotelinfoData.hotelinfo.anreise.sehenswuerdigkeit1.dauerZuFussInMinuten;
    }
    if (!hotelinfoData.hotelinfo.anreise.sehenswuerdigkeit2.dauerZuFussInMinuten) {
        delete hotelinfoData.hotelinfo.anreise.sehenswuerdigkeit2.dauerZuFussInMinuten;
    }
    if (!hotelinfoData.hotelinfo.anreise.sehenswuerdigkeit3.dauerZuFussInMinuten) {
        delete hotelinfoData.hotelinfo.anreise.sehenswuerdigkeit3.dauerZuFussInMinuten;
    }
    // Allgemein letzte Renovierung
    if (!hotelinfoData.hotelinfo.allgemein.letzteRenovierung.monat) {
        delete hotelinfoData.hotelinfo.allgemein.letzteRenovierung.monat;
    }
    if (!hotelinfoData.hotelinfo.allgemein.letzteRenovierung.jahr) {
        delete hotelinfoData.hotelinfo.allgemein.letzteRenovierung.jahr;
    }
    // Renovierung bis
    if (!hotelinfoData.hotelinfo.allgemein.renovierungBis.monat) {
        delete hotelinfoData.hotelinfo.allgemein.renovierungBis.monat;
    }
    if (!hotelinfoData.hotelinfo.allgemein.renovierungBis.jahr) {
        delete hotelinfoData.hotelinfo.allgemein.renovierungBis.jahr;
    }
    // Gastronomie Frühstück Öffnungszeiten
    if (isNaN(hotelinfoData.hotelinfo.gastronomie.fruehstueckszeiten.von.stunde)) {
        delete hotelinfoData.hotelinfo.gastronomie.fruehstueckszeiten.von;
    }
    if (isNaN(hotelinfoData.hotelinfo.gastronomie.fruehstueckszeiten.bis.stunde)) {
        delete hotelinfoData.hotelinfo.gastronomie.fruehstueckszeiten.bis;
    }
    // Gastronomie Frühstück abweichende Öffnungszeiten
    if (isNaN(hotelinfoData.hotelinfo.gastronomie.abweichendeFruehstueckszeitenAmWochenende.von.stunde)) {
        delete hotelinfoData.hotelinfo.gastronomie.abweichendeFruehstueckszeitenAmWochenende.von;
    }
    if (isNaN(hotelinfoData.hotelinfo.gastronomie.abweichendeFruehstueckszeitenAmWochenende.bis.stunde)) {
        delete hotelinfoData.hotelinfo.gastronomie.abweichendeFruehstueckszeitenAmWochenende.bis;
    }
    // Gastronomie Abendessen Öffnungszeiten
    if (isNaN(hotelinfoData.hotelinfo.gastronomie.abendessenzeiten.von.stunde)) {
        delete hotelinfoData.hotelinfo.gastronomie.abendessenzeiten.von;
    }
    if (isNaN(hotelinfoData.hotelinfo.gastronomie.abendessenzeiten.bis.stunde)) {
        delete hotelinfoData.hotelinfo.gastronomie.abendessenzeiten.bis;
    }
    // Gastronomie Baröffnungszeiten
    if (isNaN(hotelinfoData.hotelinfo.gastronomie.barOeffnungszeiten.von.stunde)) {
        delete hotelinfoData.hotelinfo.gastronomie.barOeffnungszeiten.von;
    }
    if (isNaN(hotelinfoData.hotelinfo.gastronomie.barOeffnungszeiten.bis.stunde)) {
        delete hotelinfoData.hotelinfo.gastronomie.barOeffnungszeiten.bis;
    }
    return hotelinfoData;
}

module.exports.handleIncomingHotelinfoData = handleIncomingHotelinfoData;
module.exports.handleOutgoingHotelinfoData = handleOutgoingHotelinfoData;
