import React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "./Checkbox";

export function CVal (props) {
    const { i18n } = useTranslation();
    let output = props.value;
    if ((props.hourFrom !== undefined) && (props.minuteFrom !== undefined)) {
        output = <>
            <span className="CValue">
                {getHour(props.hourFrom)}:{getMinute(props.minuteFrom)}
            </span>
            {"-"}
            <span className="CValue">
                {getHour(props.hourTo)}:{getMinute(props.minuteTo)}
            </span>
        </>;
    } else if ((output === true) || (output === false)) {
        output = <Checkbox value={output}/>;
    } else if (props.type === "text") {
        output = <div className="CText">{output}</div>;
    } else if (props.type === "time") {
        output = <span className="CValue">{output}</span>;
    } else if (Number(props.value) === parseInt(props.value)) {
        output = <span className="CValue">{output}</span>;
    } else {
        const assumedKey = props.label + "_" + props.value;
        if (assumedKey !== i18n.t(assumedKey)) {
            output = i18n.t(assumedKey);
        }
        output = <span className="CValue">{i18n.t(output)}</span>;
    }
    return <>{output}</>;
}

function getHour (hour) {
    return hour > 9 ? hour : "0" + hour;
}

function getMinute (minute) {
    return minute > 9 ? minute : "0" + minute;
}
