import i18next from "i18next";
import React, { useState } from "react";
import { FloatingLabel } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useHotelinfoData, useUpdateHotelinfoData } from "../contextProvider/HotelinfoContext";

export function Textarea (props) {
    const hotelinfoData = useHotelinfoData();
    const updateHotelinfoData = useUpdateHotelinfoData();
    const { i18n } = useTranslation();
    const placeholder = props.placeholder ? props.placeholder : props.label;
    const controlId = props.controlId ? props.controlId : props.label;
    const [val, setVal] = useState(props.initialValue[i18n.resolvedLanguage]);
    const [lang, setLang] = useState(i18n.resolvedLanguage);

    function handleChange (event) {
        const { name, value } = event.target;
        setVal(value);
        const keyArray = name.split("_");
        if (keyArray.length) {
            if (keyArray.length === 2) {
                hotelinfoData.hotelinfo[keyArray[0]][keyArray[1]][lang] = value;
            } else if (keyArray.length === 3) {
                hotelinfoData.hotelinfo[keyArray[0]][keyArray[1]][keyArray[2]][lang] = value;
            }
            updateHotelinfoData(hotelinfoData);
        }
    }

    i18next.on("languageChanged", function (lng) {
        setLang(lng);
        setVal(props.initialValue[lng]);
    });

    return (
        <FloatingLabel controlId={controlId} label={i18n.t(props.label)}>
            <Form.Control
                as="textarea"
                name={controlId}
                required={props.required === true}
                placeholder={i18n.t(placeholder)}
                onChange={handleChange}
                style={{ height: "100px" }}
                value={val}
            />
        </FloatingLabel>
    );
}
