
function getAusweisArray () {
    const array = [];
    array.push({
        name: "anreise_checkIn_ausweis_nichtNotwendig",
        value: "nichtNotwendig"
    });
    array.push({
        name: "anreise_checkIn_ausweis_proPerson",
        value: "proPerson"
    });
    array.push({
        name: "anreise_checkIn_ausweis_proZimmer",
        value: "proZimmer"
    });
    array.push({
        name: "anreise_checkIn_ausweis_reiseleiterProGruppe",
        value: "reiseleiterProGruppe"
    });
    return array;
}

function getBusparkplatzArray () {
    const array = [];
    array.push({
        name: "anreise_busparkplatzAmHotel_direktAmHotel",
        value: "direktAmHotel"
    });
    array.push({
        name: "anreise_busparkplatzAmHotel_extern",
        value: "extern"
    });
    array.push({
        name: "anreise_busparkplatzAmHotel_kein",
        value: "kein"
    });
    return array;
}

function getCheckInArray () {
    const array = [];
    array.push({
        name: "anreise_checkIn_checkIn_proZimmer",
        value: "proZimmer"
    });
    array.push({
        name: "anreise_checkIn_checkIn_reiseleiterProGruppe",
        value: "reiseleiterProGruppe"
    });
    return array;
}

function getKreditkarteArray () {
    const array = [];
    array.push({
        name: "anreise_checkIn_kreditkarte_nichtNotwendig",
        value: "nichtNotwendig"
    });
    array.push({
        name: "anreise_checkIn_kreditkarte_proZimmer",
        value: "proZimmer"
    });
    array.push({
        name: "anreise_checkIn_kreditkarte_reiseleiterProGruppe",
        value: "reiseleiterProGruppe"
    });
    return array;
}

module.exports.getAusweisArray = getAusweisArray;
module.exports.getBusparkplatzArray = getBusparkplatzArray;
module.exports.getCheckInArray = getCheckInArray;
module.exports.getKreditkarteArray = getKreditkarteArray;
