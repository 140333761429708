import "./i18n";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <React.Suspense fallback="loading">
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </React.Suspense>
    </React.StrictMode>
);
