import ErrorMessage from "./elements/finalize/ErrorMessage";
import helper from "./hotelinfo/data/helper";
import ContentHeader from "./elements/header/Content";
import Finalize from "./Finalize";
import Homepage from "./Homepage";
import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "axios";
import { Allgemein } from "./hotelinfo/Allgemein";
import { Anreise } from "./hotelinfo/Anreise";
import { Freizeit } from "./hotelinfo/Freizeit";
import { Gastronomie } from "./hotelinfo/Gastronomie";
import { Zimmer } from "./hotelinfo/Zimmer";
import { useHotelinfoData } from "./contextProvider/HotelinfoContext";
import { useTabContext, useUpdateTabData } from "./contextProvider/TabContext";
import { useTranslation } from "react-i18next";

// cra-envs
import { getEnv } from "../env";

const Content = (props) => {
    const hotelinfoData = useHotelinfoData();
    const tabContext = useTabContext();
    const updateTabContextData = useUpdateTabData();
    const { i18n } = useTranslation();
    const [activeTab, setActiveTab] = useState("homepage");
    const [finalizeDisabled, setFinalizeDisabled] = useState(true);
    const [tabsDisabled, setTabsDisabled] = useState(false);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const activateTab = (tab) => {
        setActiveTab(tab);
        tabContext.activeTab = tab;
    };

    const switchTab = (tab) => {
        let disabled = false;
        let progress = 0;
        for (const key in tabContext.validation) {
            if (!tabContext.validation[key]) {
                disabled = true;
            } else {
                progress = progress + 20;
                setProgress(progress);
            }
        }
        setFinalizeDisabled(disabled);
        if (!disabled || (tab !== "finalize")) {
            setActiveTab(tab);
            tabContext.activeTab = tab;
            updateTabContextData(tabContext);
        }
        setTabsDisabled(false);
    };

    // cra-envs
    const env = getEnv();
    const handleSaveAndContinue = (next) => {
        const headers = {
            Authorization: env["AUTHORIZATION_KEY"],
            token: props.token
        };
        hotelinfoData.hotelinfo.bearbeiter.sprache = i18n.resolvedLanguage;
        const url = "/api/hotels/" + hotelinfoData.adressnummer;
        axios.put(url, helper.handleOutgoingHotelinfoData(hotelinfoData), { headers })
            .then(() => {
                setShowError(false);
                switchTab(next);
            })
            .catch((e) => {
                console.log(e.toString());
                setError(e.toString());
                setShowError(true);
            });
    };

    return <>
        <ContentHeader hotel={props.hotel} activeTab={tabContext.activeTab} progress={progress}/>
        <div className="Content">
            <Tabs
                activeKey={activeTab}
                id="uncontrolled-hotelinfo-tabs"
                className="mb-3" onSelect={activateTab}>
                <Tab eventKey="homepage" title={i18n.t("start")} disabled={tabsDisabled}>
                    <Homepage switchTab={switchTab}/>
                </Tab>
                <Tab eventKey="hotelinfo_allgemein" title={i18n.t("hotelinfo_allgemein")} disabled={tabsDisabled}>
                    <Allgemein category="hotelinfo_allgemein" handleSaveAndContinue={handleSaveAndContinue}/>
                </Tab>
                <Tab eventKey="hotelinfo_anreise" title={i18n.t("hotelinfo_anreise")} disabled={tabsDisabled}>
                    <Anreise category="hotelinfo_anreise" handleSaveAndContinue={handleSaveAndContinue}/>
                </Tab>
                <Tab eventKey="hotelinfo_zimmer" title={i18n.t("hotelinfo_zimmer")} disabled={tabsDisabled}>
                    <Zimmer category="hotelinfo_zimmer" handleSaveAndContinue={handleSaveAndContinue}/>
                </Tab>
                <Tab eventKey="hotelinfo_gastronomie" title={i18n.t("hotelinfo_gastronomie")} disabled={tabsDisabled}>
                    <Gastronomie category="hotelinfo_gastronomie" handleSaveAndContinue={handleSaveAndContinue}/>
                </Tab>
                <Tab eventKey="hotelinfo_freizeit" title={i18n.t("hotelinfo_freizeit")} disabled={tabsDisabled}>
                    <Freizeit category="hotelinfo_freizeit" handleSaveAndContinue={handleSaveAndContinue}/>
                </Tab>
                <Tab eventKey="finalize" title={i18n.t("abschliessen")} disabled={finalizeDisabled}>
                    <Finalize setTabsDisabled={setTabsDisabled}/>
                </Tab>
            </Tabs>
            <ErrorMessage show={showError} error={error}/>
        </div>
    </>;
};

export default Content;
