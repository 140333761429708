import "./App.css";
import { ThemeProvider } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Footer from "./components/elements/Footer";
import Header from "./components/elements/Header";
import Hotelinfo from "./components/Hotelinfo";
import NotFound from "./components/genericPages/NotFound";
import React from "react";
import StartPage from "./components/genericPages/StartPage";
import { HotelinfoProvider } from "./components/contextProvider/HotelinfoContext";
import { TabContextProvider } from "./components/contextProvider/TabContext";
import { Route, Routes } from "react-router-dom";

function App () {
    return (
        <ThemeProvider
            breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm"]}
            minBreakpoint="md">
            <Container fluid>
                <Header/>
                <Routes>
                    <Route path="/" element={
                        <StartPage/>
                    }/>
                    <Route path="/hotel/:addressNumber/:token" element={
                        <HotelinfoProvider>
                            <TabContextProvider>
                                <Hotelinfo/>
                            </TabContextProvider>
                        </HotelinfoProvider>
                    }/>
                    <Route path="*" element={
                        <NotFound/>
                    }/>
                </Routes>
                <Footer/>
            </Container>
        </ThemeProvider>
    );
}

export default App;
