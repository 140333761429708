import React from "react";
import Col from "react-bootstrap/Col";
const NotFound = (props) => {
    return <div className="Content">
        <Col>
            <h3 className="Startpage">GRÜEZI!</h3>
            <h5 className="Startpage">Willkommen bei der SERVICE-REISEN CH GmbH.</h5>
            <p className="Startpage">Sie haben nicht gefunden wonach Sie gesucht haben?<br/>
                <a href="https://www.servicereisen.com/fuer-leistungsgeber" className="Startpage">Sprechen Sie uns an</a>,
                wir finden die richtige Lösung für Sie.</p>
        </Col>
    </div>;
};
export default NotFound;
