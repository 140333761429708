import React from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

const Homepage = (props) => {
    const { i18n } = useTranslation();

    function createHtml () {
        return { __html: i18n.t("begruessungstext") };
    }

    return (
        <>
            <div dangerouslySetInnerHTML={createHtml()}/>
            <Button className="Button" variant="outline-success" onClick={() => props.switchTab("hotelinfo_allgemein")}>
                {i18n.t("button_weiter")}
            </Button>
        </>
    );
};
export default Homepage;
