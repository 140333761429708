import React from "react";

const Footer = (props) => {
    return <>
        <div className="Footer1">
            <a href="https://www.servicereisen.com/datenschutz">DATENSCHUTZ</a>
            |
            <a href="https://www.servicereisen.com/impressum">IMPRESSUM</a>
            |
            <a href="https://www.servicereisen.com/vertragsbedingungen">VERTRAGSBEDINGUNGEN</a>
        </div>
        <div className="Footer2">
            <span className="fw-bold">SERVICE-REISEN CH GMBH</span> | SEESTRASSE 46, CH-8598 BOTTIGHOFEN | +41 71 552 5500 | INFO@SERVICEREISEN.COM
        </div>
    </>;
};

export default Footer;
