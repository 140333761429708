import React from "react";

const AddressHeader = (props) => {
    const hotel = props.hotel;
    return (
        <div className="NameHotel">
            {hotel.name} {hotel.nameZusatz}
            <div className="Anschrift">
                {hotel.strasse} | {hotel.land} {hotel.postleitzahl} {hotel.ort}
            </div>
        </div>
    );
};

export default AddressHeader;
