import React from "react";
import { useTranslation } from "react-i18next";
import { CVal } from "./Value";

export function CRow (props) {
    const { i18n } = useTranslation();
    return <li className="Conclusion">
        <span className="CLabel">{i18n.t(props.label)}:</span>
        <CVal
            label={props.label} type={props.type} value={props.value}
            hourFrom={props.hourFrom} hourTo={props.hourTo} minuteFrom={props.minuteFrom} minuteTo={props.minuteTo} />
    </li>;
}
