import options from "./options/allgemein";
import AlertMessage from "../elements/AlertMessage";
import { SaveAndContinue } from "../elements/buttons/SaveAndContinue";
import { Checkbox } from "../inputfields/Checkbox";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { H4 } from "../elements/H4";
import { H5 } from "../elements/H5";
import { Input } from "../inputfields/Input";
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import { SelectBox } from "../inputfields/SelectBox";
import { Textarea } from "../inputfields/Textarea";
import { useHotelinfoData } from "../contextProvider/HotelinfoContext";
import { useTabContext, useUpdateTabData } from "../contextProvider/TabContext";

export function Allgemein (props) {
    const hotelinfoData = useHotelinfoData();
    const allgemein = hotelinfoData.hotelinfo.allgemein;
    const bearbeiter = hotelinfoData.hotelinfo.bearbeiter;
    const tabContext = useTabContext();
    const updateTabContextData = useUpdateTabData();
    const [validated, setValidated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [renovierungBisMonatDisabled, setRenovierungBisMonatDisabled] = useState(!allgemein.renovierungImGang);
    const [renovierungBisJahrDisabled, setRenovierungBisJahrDisabled] = useState(!allgemein.renovierungImGang);
    const [renovierungBisMonat, setRenovierungBisMonat] = useState(allgemein.renovierungBis.monat);
    const [renovierungBisJahr, setRenovierungBisJahr] = useState(allgemein.renovierungBis.jahr);
    const [renovierungBisRequired, setRenorierungBisRequired] = useState(
        renovierungBisMonat || renovierungBisJahr
    );
    const [letzteRenovierungMonat, setLetzteRenovierungMonat] = useState(allgemein.letzteRenovierung.monat);
    const [letzteRenovierungJahr, setLetzteRenovierungJahr] = useState(allgemein.letzteRenovierung.jahr);
    const [letzteRenorierungRequired, setLetzteRenorierungRequired] = useState(
        letzteRenovierungMonat || letzteRenovierungJahr
    );
    const [wlanImHotelGratisDisabled, setWlanImHotelGratisDisabled] = useState(!allgemein.wlanImHotel);
    const [wlanImHotelGratis, setWlanImHotelGratis] = useState(allgemein.wlanImHotelGratis);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, process.env.REACT_APP_ALERT_TIMEOUT);
        } else {
            setShowAlert(false);
            tabContext.validation.allgemein = true;
            updateTabContextData(tabContext);
            props.handleSaveAndContinue("hotelinfo_anreise");
        }
        setValidated(true);
    };

    const handleWlanImHotelGratis = (checked) => {
        setWlanImHotelGratisDisabled(!checked);
        if (!checked) {
            setWlanImHotelGratis(false);
        }
    };

    const handleRenovierungImGang = (checked) => {
        setRenovierungBisMonatDisabled(!checked);
        setRenovierungBisJahrDisabled(!checked);
        if (!checked) {
            setRenovierungBisMonat(null);
            setRenovierungBisJahr(null);
        }
    };

    const handleRenovierungBisMonat = (value) => {
        handleRenovierungBis(value, "monat");
    };

    const handleRenovierungBisJahr = (value) => {
        handleRenovierungBis(value, "jahr");
    };

    const handleRenovierungBis = (value, typ) => {
        let required;
        if (typ === "monat") {
            setRenovierungBisMonat(value);
            required = (value || renovierungBisJahr);
        } else {
            setRenovierungBisJahr(value);
            required = (renovierungBisMonat || value);
        }
        setRenorierungBisRequired(required);
    };

    const handleLetzteRenovierungMonat = (value) => {
        handleLetzteRenovierung(value, "monat");
    };

    const handleLetzteRenovierungJahr = (value) => {
        handleLetzteRenovierung(value, "jahr");
    };

    const handleLetzteRenovierung = (value, typ) => {
        let required;
        if (typ === "monat") {
            setLetzteRenovierungMonat(value);
            required = (value || letzteRenovierungJahr);
        } else {
            setLetzteRenovierungJahr(value);
            required = (letzteRenovierungMonat || value);
        }
        setLetzteRenorierungRequired(required);
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <H4 label="bearbeiter_kontakt"/>
            <Row className="InputPadding">
                <Col>
                    <Input label={"bearbeiter_vorname"} initialValue={bearbeiter.vorname} required={true}/>
                </Col>
                <Col>
                    <Input label={"bearbeiter_nachname"} initialValue={bearbeiter.nachname} required={true}/>
                </Col>
                <Col>
                    <Input label={"bearbeiter_email"} type="email" initialValue={bearbeiter.email} required={true}/>
                </Col>
            </Row>
            <H4 label={props.category}/>
            <Row className="InputPadding">
                <Col md={3}>
                    <SelectBox label="allgemein_landeskategorie" options={options.getLandeskategorienArray()}
                        initialValue={allgemein.landeskategorie}/>
                </Col>
                <Col md={3}>
                    <Checkbox label="allgemein_nebenGebaeudeVorhanden" initialValue={allgemein.nebenGebaeudeVorhanden}/>
                </Col>
                <Col md={2}>
                    <Checkbox label="allgemein_fahrstuhl" initialValue={allgemein.fahrstuhl}/>
                </Col>
                <Col md={2}>
                    <Checkbox label="allgemein_wlanImHotel"
                        onCallback={handleWlanImHotelGratis}
                        initialValue={allgemein.wlanImHotel}/>
                </Col>
                <Col md={2}>
                    <Checkbox label="allgemein_wlanImHotelGratis"
                        disabled={wlanImHotelGratisDisabled}
                        initialValue={wlanImHotelGratis}/>
                </Col>
            </Row>
            <H5 label="allgemein_oeffentlicheBereiche"/>
            <Row>
                <Col md={3}>
                    <SelectBox label="allgemein_lobby" options={options.getLobbyArray()} initialValue={allgemein.lobby}/>
                </Col>
                <Col md={3}>
                    <SelectBox label="allgemein_reception" options={options.getRezeptionArray()}
                        initialValue={allgemein.reception}/>
                </Col>
                <Col md={3}>
                    <Checkbox label="allgemein_klimatisierteBereiche" initialValue={allgemein.klimatisierteBereiche}/>
                </Col>
            </Row>
            <H5 label="allgemein_renovierung"/>
            <Row>
                <Col md={6}>
                    <Checkbox label="allgemein_renovierungImGang"
                        onCallback={handleRenovierungImGang}
                        initialValue={allgemein.renovierungImGang}/>
                </Col>
                <Col>
                    <Input label="allgemein_renovierungBis_monat"
                        type="number"
                        disabled={renovierungBisMonatDisabled}
                        min={1} max={12}
                        required={renovierungBisRequired}
                        onCallback={handleRenovierungBisMonat}
                        initialValue={renovierungBisMonat}/>
                </Col>
                <Col>
                    <Input label="allgemein_renovierungBis_jahr"
                        type="number"
                        disabled={renovierungBisJahrDisabled}
                        min={new Date().getFullYear()}
                        max={new Date().getFullYear() + 3}
                        onCallback={handleRenovierungBisJahr}
                        required={renovierungBisRequired}
                        initialValue={renovierungBisJahr}/>
                </Col>
            </Row>
            <H5 label="allgemein_letzteRenovierung"/>
            <Row className="InputPadding">
                <Col md={6}>
                    <Textarea label="allgemein_renovierungDetails"
                        initialValue={allgemein.renovierungDetails}/>
                </Col>
                <Col>
                    <Input label="allgemein_letzteRenovierung_monat"
                        type="number"
                        min={1} max={12}
                        onCallback={handleLetzteRenovierungMonat}
                        required={letzteRenorierungRequired}
                        initialValue={letzteRenovierungMonat}/>
                </Col>
                <Col>
                    <Input label="allgemein_letzteRenovierung_jahr"
                        type="number"
                        min={new Date().getFullYear() - 50}
                        max={new Date().getFullYear()}
                        onCallback={handleLetzteRenovierungJahr}
                        required={letzteRenorierungRequired}
                        initialValue={letzteRenovierungJahr}/>
                </Col>
            </Row>
            <SaveAndContinue/>
            <AlertMessage show={showAlert}/>
        </Form>
    );
}
