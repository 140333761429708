import React from "react";
import { useTranslation } from "react-i18next";

export function CSehenswuerdigkeit (props) {
    const { i18n } = useTranslation();
    return <li className="Conclusion">
        <div>{props.beschreibung}</div>
        <span>
            {i18n.t("anreise_sehenswuerdigkeit_dauerZuFussInMinuten")}:{" "}
            {props.dauerZuFussInMinuten}
        </span>
    </li>;
}
