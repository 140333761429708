import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import React from "react";
import i18n from "i18next";

const LanguageSwitcher = (props) => {
    return (
        <div className="LanguageSwitcher">
            {
                props.hide
                    ? ""
                    : <ButtonGroup aria-label="Language switcher">
                        <Button variant={i18n.resolvedLanguage === "de" ? "primary" : "secondary"}
                            onClick={() => i18n.changeLanguage("de")}>de</Button>
                        <Button variant={i18n.resolvedLanguage === "en" ? "primary" : "secondary"}
                            onClick={() => i18n.changeLanguage("en")}>en</Button>
                        <Button variant={i18n.resolvedLanguage === "fr" ? "primary" : "secondary"}
                            onClick={() => i18n.changeLanguage("fr")}>fr</Button>
                    </ButtonGroup>
            }
        </div>
    );
};

export default LanguageSwitcher;
