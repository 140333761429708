import React, { useState } from "react";
import AlertMessage from "../elements/AlertMessage";
import { SaveAndContinue } from "../elements/buttons/SaveAndContinue";
import { Checkbox } from "../inputfields/Checkbox";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { H4 } from "../elements/H4";
import { H5 } from "../elements/H5";
import { Input } from "../inputfields/Input";
import Row from "react-bootstrap/Row";
import { useHotelinfoData } from "../contextProvider/HotelinfoContext";
import { useTabContext, useUpdateTabData } from "../contextProvider/TabContext";

export function Zimmer (props) {
    const hotelinfoData = useHotelinfoData();
    const tabContext = useTabContext();
    const updateTabContextData = useUpdateTabData();
    const [validated, setValidated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, process.env.REACT_APP_ALERT_TIMEOUT);
        } else {
            setShowAlert(false);
            tabContext.validation.zimmer = true;
            updateTabContextData(tabContext);
            props.handleSaveAndContinue("hotelinfo_gastronomie");
        }
        setValidated(true);
    };
    const zimmerInfo = hotelinfoData.hotelinfo.zimmerInfo;
    const zimmerausstattung = hotelinfoData.hotelinfo.zimmerausstattung;
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <H4 label={props.category}/>
            <Row className="InputPadding">
                <Col md={3}>
                    <Input label="zimmerInfo_anzahlEtagen" required={true}
                        type="number" min={1}
                        initialValue={zimmerInfo.anzahlEtagen}/>
                </Col>
                <Col md={3}>
                    <Checkbox label="zimmerInfo_stdDreibettzimmerVorhanden"
                        initialValue={zimmerInfo.stdDreibettzimmerVorhanden}/>
                </Col>
                <Col md={3}>
                    <Checkbox label="zimmerInfo_spZimmerVorhanden" initialValue={zimmerInfo.spZimmerVorhanden}/>
                </Col>
            </Row>
            <Row className="InputPadding">
                <Col md={3}>
                    <Input label="zimmerInfo_einzelzimmer" required={true}
                        type="number" min={0}
                        initialValue={zimmerInfo.einzelzimmer}/>
                </Col>
                <Col md={3}>
                    <Input label="zimmerInfo_doppelzimmer" required={true}
                        type="number" min={0}
                        initialValue={zimmerInfo.doppelzimmer}/>
                </Col>
                <Col md={3}>
                    <Input
                        label="zimmerInfo_doppelzimmerTwin" required={true}
                        type="number" min={0}
                        initialValue={zimmerInfo.doppelzimmerTwin}/>
                </Col>
                <Col md={3}>
                    <Input label="zimmerInfo_rollstuhlgerechteZimmer" required={true}
                        type="number" min={0}
                        initialValue={zimmerInfo.rollstuhlgerechteZimmer}/>
                </Col>
            </Row>
            <H5 label="zimmerInfo_zimmerausstattung"/>
            <Row className="InputPadding">
                <Col md={3}>
                    <Checkbox label="zimmerausstattung_balkon" initialValue={zimmerausstattung.balkon}/>
                </Col>
                <Col md={3}>
                    <Checkbox label="zimmerausstattung_telefon" initialValue={zimmerausstattung.telefon}/>
                </Col>
                <Col md={3}>
                    <Checkbox label="zimmerausstattung_couch" initialValue={zimmerausstattung.couch}/>
                </Col>
                <Col md={3}>
                    <Checkbox label="zimmerausstattung_schreibtisch" initialValue={zimmerausstattung.schreibtisch}/>
                </Col>
            </Row>
            <Row className="InputPadding">
                <Col md={3}>
                    <Checkbox label="zimmerausstattung_safe" initialValue={zimmerausstattung.safe}/>
                </Col>
                <Col md={3}>
                    <Checkbox label="zimmerausstattung_zimmerVerdunkelbar"
                        initialValue={zimmerausstattung.zimmerVerdunkelbar}/>
                </Col>
                <Col md={3}>
                    <Checkbox label="zimmerausstattung_fensterSindZuOeffnen"
                        initialValue={zimmerausstattung.fensterSindZuOeffnen}/>
                </Col>
                <Col md={3}>
                    <Checkbox label="zimmerausstattung_einstellbareHeizung"
                        initialValue={zimmerausstattung.einstellbareHeizung}/>
                </Col>
            </Row>
            <Row className="InputPadding">
                <Col md={3}>
                    <Checkbox label="zimmerausstattung_coffeemakingFacilities"
                        initialValue={zimmerausstattung.coffeemakingFacilities}/>
                </Col>
                <Col md={3}>
                    <Checkbox label="zimmerausstattung_alleZimmerPerLiftErreichbar"
                        initialValue={zimmerausstattung.alleZimmerPerLiftErreichbar}/>
                </Col>
            </Row>
            <SaveAndContinue />
            <AlertMessage show={showAlert}/>
        </Form>
    );
}
