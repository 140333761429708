import ProgressBar from "react-bootstrap/ProgressBar";
import React from "react";

function Progress (props) {
    return <ProgressBar
        className="ProgressBar"
        variant="success"
        now={props.progress}
        label={`${props.progress}%`} />;
}

export default Progress;
