import React from "react";
import Col from "react-bootstrap/Col";
import LogoSmall from "../elements/LogoSmall";
const StartPage = (props) => {
    return <div className="Content">
        <Col>
            <h2 className="Startpage">GRÜEZI!</h2>
            <div className="Startpage-Logo-Container"><LogoSmall/></div>
            <h5 className="Startpage">Willkommen bei der SERVICE-REISEN CH GmbH.</h5>
            <p className="Startpage">Wir blicken derzeit mit viel Vorfreude auf die Reisesaison 2023
                und freuen uns Ihnen unser umfangreiches Angebot präsentieren zu dürfen.<br/>
                Wir bieten Ihnen für den stark wachsenden Trend nach Kreuzfahrten ein umfangreiches Angebot
                an Fluss- und Hochseekreuzfahrten.<br/>
                Unsere Angebote richten sich ausschliesslich an B2B-Kunden,
                d.h. Wiederverkäufer und Reiseveranstalter können bei uns preislich attraktive Reiseideen auswählen.</p>

            <p className="Startpage">Daneben finden Sie bei unserem Vertriebspartner{" "}
                <a href="https://www.servicereisen.de/" className="Startpage">SERVICE-REISEN in Giessen</a>{" "}
                eine riesige Auswahl an Gruppenreisen. <br/>
                Haben Sie bereits konkrete Vorstellungen? <a href="https://www.servicereisen.com/fuer-leistungsgeber" className="Startpage">Sprechen Sie uns an</a>,
                wir finden die richtige Lösung für Sie.</p>
        </Col>
    </div>;
};
export default StartPage;
