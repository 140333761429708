import React from "react";
import { CRow } from "./Element";

export function CLetzteRenovierung (props) {
    const month = props.monat || "";
    const year = props.jahr || "";
    const slash = props.jahr ? " / " : "";
    const value = month + slash + year;
    if (!value) return null;
    return <CRow label={props.label} value={value}/>;
}
