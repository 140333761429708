import { Textarea } from "../inputfields/Textarea";
import options from "./options/anreise";
import React, { useState } from "react";
import AlertMessage from "../elements/AlertMessage";
import { SaveAndContinue } from "../elements/buttons/SaveAndContinue";
import { Checkbox } from "../inputfields/Checkbox";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { H4 } from "../elements/H4";
import { H5 } from "../elements/H5";
import { Input } from "../inputfields/Input";
import Row from "react-bootstrap/Row";
import { Sehenswuerdigkeiten } from "../inputfildGroups/Sehenswuerdigkeiten";
import { SelectBox } from "../inputfields/SelectBox";
import { useHotelinfoData } from "../contextProvider/HotelinfoContext";
import { useTabContext, useUpdateTabData } from "../contextProvider/TabContext";

export function Anreise (props) {
    const hotelinfoData = useHotelinfoData();
    const tabContext = useTabContext();
    const updateTabContextData = useUpdateTabData();
    const [validated, setValidated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, process.env.REACT_APP_ALERT_TIMEOUT);
        } else {
            setShowAlert(false);
            tabContext.validation.anreise = true;
            updateTabContextData(tabContext);
            props.handleSaveAndContinue("hotelinfo_zimmer");
        }
        setValidated(true);
    };
    const anreise = hotelinfoData.hotelinfo.anreise;
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <H4 label={props.category}/>
            <Row className="InputPadding">
                <Col>
                    <H5 label="anreise_checkIn"/>
                    <Row>
                        <Col>
                            <SelectBox label="anreise_checkIn_ausweis" initialValue={anreise.checkIn.ausweis}
                                options={options.getAusweisArray()}/>
                        </Col>
                        <Col>
                            <SelectBox label="anreise_checkIn_kreditkarte" initialValue={anreise.checkIn.kreditkarte}
                                options={options.getKreditkarteArray()}/>
                        </Col>
                        <Col>
                            <SelectBox label="anreise_checkIn_checkIn" initialValue={anreise.checkIn.checkIn}
                                options={options.getCheckInArray()}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="InputPadding">
                <Col>
                    <H5 label="anreise_busparkplatzAmHotel"/>
                    <Row className="InputPadding">
                        <Col>
                            <Input label="anreise_anzahlStellplaetze" type="number"
                                initialValue={anreise.anzahlStellplaetze} required={true}/>
                        </Col>
                        <Col>
                            <Checkbox label="anreise_hotelanfahrtBusgeeignet" initialValue={anreise.hotelanfahrtBusgeeignet}/>
                        </Col>
                        <Col>
                            <SelectBox label="anreise_busparkplatzAmHotel" initialValue={anreise.busparkplatzAmHotel}
                                options={options.getBusparkplatzArray()}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <H5 label="anreise_busparkplatzExtern"/>
            <Row className="InputPadding">
                <Col md={6}>
                    <Input label="anreise_busparkplatz_adresse_name"
                        initialValue={anreise.busparkplatz.adresse.name}/>
                </Col>
                <Col>
                    <Input label="anreise_busparkplatz_adresse_nameZusatz"
                        initialValue={anreise.busparkplatz.adresse.nameZusatz}/>
                </Col>
            </Row>
            <Row className="InputPadding">
                <Col md={6}>
                    <Input label="anreise_busparkplatz_adresse_strasse"
                        initialValue={anreise.busparkplatz.adresse.strasse}/>
                </Col>
                <Col md={2}>
                    <Input label="anreise_busparkplatz_adresse_plz"
                        initialValue={anreise.busparkplatz.adresse.plz}/>
                </Col>
                <Col>
                    <Input label="anreise_busparkplatz_adresse_ort"
                        initialValue={anreise.busparkplatz.adresse.ort}/>
                </Col>
            </Row>
            <Row className="InputPadding">
                <Col xs={8}>
                    <Textarea label="anreise_busparkplatz_erreichbarkeit"
                        initialValue={anreise.busparkplatz.erreichbarkeit}/>
                </Col>
                <Col md={4}>
                    <Input label="anreise_busparkplatz_entfernungZumHotel" type="number"
                        initialValue={anreise.busparkplatz.entfernungZumHotel}/>
                </Col>
            </Row>
            <H5 label="ueberschrift_sehenswuerdigkeiten"/>
            <Row className="InputPadding">
                <Sehenswuerdigkeiten number={1} defaultData={anreise.sehenswuerdigkeit1}/>
            </Row>
            <Row className="InputPadding">
                <Sehenswuerdigkeiten number={2} defaultData={anreise.sehenswuerdigkeit2}/>
            </Row>
            <Row className="InputPadding">
                <Sehenswuerdigkeiten number={3} defaultData={anreise.sehenswuerdigkeit3}/>
            </Row>
            <SaveAndContinue />
            <AlertMessage show={showAlert}/>
        </Form>
    );
}
