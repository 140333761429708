import Col from "react-bootstrap/Col";
import { Input } from "../inputfields/Input";
import React from "react";

export function Sehenswuerdigkeiten (props) {
    const labelBeschreibung = "anreise_sehenswuerdigkeit_beschreibung"; // beschreibung (!)
    const labelDauerZuFussInMinuten = "anreise_sehenswuerdigkeit_dauerZuFussInMinuten";
    const keyBeschreibung = "anreise_sehenswuerdigkeit" + props.number + "_bezeichnung"; // bezeichnung (!)
    const keyDauerZuFussInMinuten = "anreise_sehenswuerdigkeit" + props.number + "_dauerZuFussInMinuten";
    return (
        <>
            <Col md={6}>
                <Input label={labelBeschreibung} controlId={keyBeschreibung}
                    initialValue={props.defaultData.bezeichnung}/>
            </Col>
            <Col md={2}>
                <Input label={labelDauerZuFussInMinuten} controlId={keyDauerZuFussInMinuten} type="number"
                    initialValue={props.defaultData.dauerZuFussInMinuten}/>
            </Col>
        </>
    );
}
