import React, { useContext, useState } from "react";

const HotelinfoContext = React.createContext({});
const TokenContext = React.createContext({});
const HotelinfoUpdateContext = React.createContext();
const TokenUpdateContext = React.createContext();

export function useHotelinfoData () {
    return useContext(HotelinfoContext);
}

export function useTokenData () {
    return useContext(TokenContext);
}

export function useUpdateHotelinfoData () {
    return useContext(HotelinfoUpdateContext);
}

export function useUpdateTokenData () {
    return useContext(TokenUpdateContext);
}

export function HotelinfoProvider ({ children }) {
    const [hotelinfoData, setHotelinfoData] = useState({});
    const [tokenData, setTokenData] = useState({});

    function updateHotelinfoData (data) {
        setHotelinfoData(data);
    }

    function updateTokenData (data) {
        setTokenData(data);
    }

    return (
        <HotelinfoContext.Provider value={hotelinfoData}>
            <TokenContext.Provider value={tokenData}>
                <HotelinfoUpdateContext.Provider value={updateHotelinfoData}>
                    <TokenUpdateContext.Provider value={updateTokenData}>
                        {children}
                    </TokenUpdateContext.Provider>
                </HotelinfoUpdateContext.Provider>
            </TokenContext.Provider>
        </HotelinfoContext.Provider>
    );
};
