import axios from "axios";
import helper from "./hotelinfo/data/helper";
import { useTranslation } from "react-i18next";
import Content from "./Content";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useHotelinfoData, useUpdateHotelinfoData, useUpdateTokenData } from "./contextProvider/HotelinfoContext";
import ErrorPage from "./genericPages/ErrorPage";

// cra-envs
import { getEnv } from "../env";

function Hotelinfo (props) {
    const { i18n } = useTranslation();
    const { addressNumber, token } = useParams();
    const hotelinfoData = useHotelinfoData();
    const updateHotelinfoData = useUpdateHotelinfoData();
    const updateTokenData = useUpdateTokenData();
    const [error, setError] = useState("");
    const [errorClassName, setErrorClassName] = useState("Error");
    // cra-envs
    const env = getEnv();
    const headers = {
        Authorization: env["AUTHORIZATION_KEY"],
        token
    };
    React.useEffect(() => {
        const url = "/api/tokens/" + token;
        axios.get(url, { headers })
            .then((response) => {
                if (response.data.adressnummer === Number(addressNumber)) {
                    updateTokenData(response.data);
                    i18n.changeLanguage(response.data.sprache).then(() => {
                        const url = "/api/hotels/" + addressNumber;
                        axios.get(url, { headers })
                            .then((response) => {
                                updateHotelinfoData(helper.handleIncomingHotelinfoData(response.data));
                            })
                            .catch((error) => {
                                console.log(`Error sending request to webservice: ${error}`);
                                setError("An unexpected error has occurred. Please try again later.");
                                setErrorClassName("Error");
                            });
                    });
                } else {
                    console.log(`Address number from url path (${addressNumber}) does not match to address number from webservice (${response.data.adressnummer})`);
                    setError("Please check the URL. The data used does not match the expected data.");
                    setErrorClassName("Error");
                }
            })
            .catch((e) => {
                const error = e.response.data.error;
                if ((error === "token already successfully finalized") || (error === "token already delivered")) {
                    console.log(`${e.response.data.error}`);
                    setError(i18n.t("alert_already_completed"));
                    setErrorClassName("PositiveError");
                } else if ((error === "token expired")) {
                    console.log(`${e.response.data.error}`);
                    setError(i18n.t("alert_expired") + " info@servicereisen.com");
                    setErrorClassName("PositiveError");
                } else {
                    console.log(`Token ${token} invalid: ${JSON.stringify(e.response.data.error)}`);
                    setError("Please check the URL. The data used does not match the expected data.");
                    setErrorClassName("Error");
                }
            });
    }, []);
    if (!hotelinfoData.hotelinfo) {
        return (<ErrorPage error={error} className={errorClassName}/>);
    }
    const hotel = {
        adressnummer: hotelinfoData.adressnummer,
        name: hotelinfoData.name,
        strasse: hotelinfoData.strasse,
        ort: hotelinfoData.ort,
        postleitzahl: hotelinfoData.postleitzahl,
        land: hotelinfoData.land,
        createdAt: hotelinfoData.createdAt,
        updatedAt: hotelinfoData.updatedAt
    };
    return (
        <Content token={token} hotel={hotel}/>
    );
}

export default Hotelinfo;
