import React from "react";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";

function ClosingMessage (props) {
    const { i18n } = useTranslation();
    return (
        <Alert show={props.show} variant="success">
            <Alert.Heading>{i18n.t("abschliessen_erfolgreich")}</Alert.Heading>
            <p>{i18n.t("abschliessen_danksagung")}</p>
        </Alert>
    );
}

export default ClosingMessage;
