import React from "react";
import LogoSmall from "../elements/LogoSmall";
const ErrorPage = (props) => {
    const className = props.className || "Error";
    return <div className="Content">
        <div className="Error-Logo-Container">
            <LogoSmall/>
        </div>
        <div className={className}>
            <div>{props.error}</div>
        </div>
    </div>;
};
export default ErrorPage;
