import axios from "axios";
import { createBrowserHistory } from "history";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useHotelinfoData, useTokenData } from "./contextProvider/HotelinfoContext";
import ClosingMessage from "./elements/finalize/ClosingMessage";
import { CRow } from "./elements/finalize/Element";
import ErrorMessage from "./elements/finalize/ErrorMessage";
import { CLetzteRenovierung } from "./elements/finalize/LetzteRenovierung";
import { CSehenswuerdigkeit } from "./elements/finalize/Sehenswuerdigkeit";
import { H4 } from "./elements/H4";
import { H5 } from "./elements/H5";

// cra-envs
import { getEnv } from "../env";

const Finalize = (props) => {
    const { i18n } = useTranslation();
    const hotelinfo = useHotelinfoData().hotelinfo;
    const tokenData = useTokenData();
    const allgemein = hotelinfo.allgemein;
    const bearbeiter = hotelinfo.bearbeiter;
    const anreise = hotelinfo.anreise;
    const freizeit = hotelinfo.freizeit;
    const gastronomie = hotelinfo.gastronomie;
    const zimmerInfo = hotelinfo.zimmerInfo;
    const zimmerausstattung = hotelinfo.zimmerausstattung;
    const [successDisabled, setSuccessDisabled] = useState(false);
    const [messageToRender, setMessageToRender] = useState(null);
    const history = createBrowserHistory();
    // cra-envs
    const env = getEnv();
    const finalize = () => {
        const headers = {
            Authorization: env["AUTHORIZATION_KEY"]
        };
        const url = `/api/tokens/${tokenData.token}/finalize`;
        axios.get(url, { headers })
            .then((response) => {
                setMessageToRender(<ClosingMessage show={true}/>);
                props.setTabsDisabled(true);
                setSuccessDisabled(true);
                setTimeout(() => {
                    history.push("https://servicereisen.com");
                }, process.env.REACT_APP_ALERT_TIMEOUT);
            })
            .catch((e) => {
                console.log(`Error ${e}`);
                setMessageToRender(<ErrorMessage show={true} error={e.toString()}/>);
            });
    };
    return (
        <>
            <Row>
                <Col>
                    <H4 label={"bearbeiter_kontakt"}/>
                    <ul>
                        <CRow label={"bearbeiter_vorname"} value={bearbeiter.vorname}/>
                        <CRow label={"bearbeiter_nachname"} value={bearbeiter.nachname}/>
                        <CRow label={"bearbeiter_email"} value={bearbeiter.email}/>
                    </ul>
                    <H4 label={"hotelinfo_allgemein"}/>
                    <ul>
                        <CRow label={"allgemein_landeskategorie"} value={allgemein.landeskategorie}/>
                        <CRow label={"allgemein_nebenGebaeudeVorhanden"} value={allgemein.nebenGebaeudeVorhanden}/>
                    </ul>
                    <H5 label="allgemein_oeffentlicheBereiche"/>
                    <ul>
                        <CRow label={"allgemein_lobby"} value={allgemein.lobby}/>
                        <CRow label={"allgemein_reception"} value={allgemein.reception}/>
                        <CRow label={"allgemein_fahrstuhl"} value={allgemein.fahrstuhl}/>
                        <CRow label={"allgemein_klimatisierteBereiche"} value={allgemein.klimatisierteBereiche}/>
                        <CRow label={"allgemein_wlanImHotel"} value={allgemein.wlanImHotel}/>
                        <CRow label={"allgemein_wlanImHotelGratis"} value={allgemein.wlanImHotelGratis}/>
                    </ul>
                    {
                        allgemein.renovierungImGang ||
                        allgemein.renovierungDetails[i18n.resolvedLanguage] ||
                        allgemein.letzteRenovierung.jahr
                            ? <H5 label="allgemein_renovierung"/>
                            : null
                    }
                    {
                        allgemein.renovierungImGang ||
                        allgemein.renovierungBis.jahr
                            ? <ul>
                                <CLetzteRenovierung label={"allgemein_renovierungImGang"}
                                    monat={allgemein.renovierungBis.monat}
                                    jahr={allgemein.renovierungBis.jahr}/>
                            </ul>
                            : null
                    }
                    {
                        allgemein.renovierungDetails[i18n.resolvedLanguage] ||
                        allgemein.letzteRenovierung.jahr
                            ? <ul>
                                <CLetzteRenovierung label={"allgemein_letzteRenovierung"}
                                    monat={allgemein.letzteRenovierung.monat}
                                    jahr={allgemein.letzteRenovierung.jahr}/>
                                <CRow label={"allgemein_renovierungDetails"}
                                    value={allgemein.renovierungDetails[i18n.resolvedLanguage]} type="text"/>
                            </ul>
                            : null
                    }
                    <H4 label={"hotelinfo_anreise"}/>
                    <H5 label="anreise_checkIn"/>
                    <ul>
                        <CRow label={"anreise_checkIn_ausweis"} value={anreise.checkIn.ausweis}/>
                        <CRow label={"anreise_checkIn_kreditkarte"} value={anreise.checkIn.kreditkarte}/>
                        <CRow label={"anreise_checkIn_checkIn"} value={anreise.checkIn.checkIn}/>
                    </ul>
                    <H5 label="anreise_anfahrt"/>
                    <ul>
                        <CRow label={"anreise_anzahlStellplaetze"} value={anreise.anzahlStellplaetze}/>
                        <CRow label={"anreise_busparkplatz_entfernungZumHotel"} value={anreise.busparkplatz.entfernungZumHotel}/>
                        <CRow label={"anreise_hotelanfahrtBusgeeignet"} value={anreise.hotelanfahrtBusgeeignet}/>
                    </ul>
                    <H5 label="anreise_busparkplatzExtern"/>
                    <ul>
                        <CRow label={"anreise_busparkplatz_adresse_name"} value={anreise.busparkplatz.adresse.name}/>
                        <CRow label={"anreise_busparkplatz_adresse_nameZusatz"} value={anreise.busparkplatz.adresse.nameZusatz}/>
                        <CRow label={"anreise_busparkplatz_adresse_strasse"} value={anreise.busparkplatz.adresse.strasse}/>
                        <CRow label={"anreise_busparkplatz_adresse_plz"} value={anreise.busparkplatz.adresse.plz}/>
                        <CRow label={"anreise_busparkplatz_adresse_ort"} value={anreise.busparkplatz.adresse.ort}/>
                        <CRow label={"anreise_busparkplatz_erreichbarkeit"} value={anreise.busparkplatz.erreichbarkeit[i18n.resolvedLanguage]}
                            type="text"/>
                    </ul>
                    <H5 label="ueberschrift_sehenswuerdigkeiten"/>
                    <ul>
                        {
                            anreise.sehenswuerdigkeit2.bezeichnung
                                ? <CSehenswuerdigkeit label={"anreise_sehenswuerdigkeit_beschreibung"}
                                    beschreibung={anreise.sehenswuerdigkeit1.bezeichnung}
                                    dauerZuFussInMinuten={anreise.sehenswuerdigkeit1.dauerZuFussInMinuten}/>
                                : ""
                        }
                        {
                            anreise.sehenswuerdigkeit2.bezeichnung
                                ? <CSehenswuerdigkeit label={"anreise_sehenswuerdigkeit_beschreibung"}
                                    beschreibung={anreise.sehenswuerdigkeit2.bezeichnung}
                                    dauerZuFussInMinuten={anreise.sehenswuerdigkeit2.dauerZuFussInMinuten}/>
                                : ""
                        }
                        {
                            anreise.sehenswuerdigkeit3.bezeichnung
                                ? <CSehenswuerdigkeit label={"anreise_sehenswuerdigkeit_beschreibung"}
                                    beschreibung={anreise.sehenswuerdigkeit3.bezeichnung}
                                    dauerZuFussInMinuten={anreise.sehenswuerdigkeit3.dauerZuFussInMinuten}/>
                                : ""
                        }
                    </ul>
                    <H4 label={"hotelinfo_zimmer"}/>
                    <H5 label="zimmerInfo"/>
                    <ul>
                        <CRow label={"zimmerInfo_anzahlEtagen"} value={zimmerInfo.anzahlEtagen}/>
                        <CRow label={"zimmerInfo_einzelzimmer"} value={zimmerInfo.einzelzimmer}/>
                        <CRow label={"zimmerInfo_doppelzimmer"} value={zimmerInfo.doppelzimmer}/>
                        <CRow label={"zimmerInfo_doppelzimmerTwin"} value={zimmerInfo.doppelzimmerTwin}/>
                        <CRow label={"zimmerInfo_rollstuhlgerechteZimmer"} value={zimmerInfo.rollstuhlgerechteZimmer}/>
                        <CRow label={"zimmerInfo_stdDreibettzimmerVorhanden"} value={zimmerInfo.stdDreibettzimmerVorhanden}/>
                        <CRow label={"zimmerInfo_spZimmerVorhanden"} value={zimmerInfo.spZimmerVorhanden}/>
                    </ul>
                    <H5 label="zimmerInfo_zimmerausstattung"/>
                    <ul>
                        <CRow label={"zimmerausstattung_balkon"} value={zimmerausstattung.balkon}/>
                        <CRow label={"zimmerausstattung_telefon"} value={zimmerausstattung.telefon}/>
                        <CRow label={"zimmerausstattung_couch"} value={zimmerausstattung.couch}/>
                        <CRow label={"zimmerausstattung_schreibtisch"} value={zimmerausstattung.schreibtisch}/>
                        <CRow label={"zimmerausstattung_coffeemakingFacilities"} value={zimmerausstattung.coffeemakingFacilities}/>
                        <CRow label={"zimmerausstattung_safe"} value={zimmerausstattung.safe}/>
                        <CRow label={"zimmerausstattung_zimmerVerdunkelbar"} value={zimmerausstattung.zimmerVerdunkelbar}/>
                        <CRow label={"zimmerausstattung_fensterSindZuOeffnen"} value={zimmerausstattung.fensterSindZuOeffnen}/>
                        <CRow label={"zimmerausstattung_einstellbareHeizung"} value={zimmerausstattung.einstellbareHeizung}/>
                        <CRow label={"zimmerausstattung_alleZimmerPerLiftErreichbar"} value={zimmerausstattung.alleZimmerPerLiftErreichbar}/>
                    </ul>
                </Col>
                <Col>
                    <H4 label={"hotelinfo_gastronomie"}/>
                    <ul>
                        <CRow label={"gastronomie_anzahlRestaurants"} value={gastronomie.anzahlRestaurants}/>
                        <CRow label={"gastronomie_anzahlSitzplaetze"} value={gastronomie.anzahlSitzplaetze}/>
                        <CRow label={"gastronomie_cafeBistroVorhanden"} value={gastronomie.cafeBistroVorhanden}/>
                        <CRow label={"gastronomie_barVorhanden"} value={gastronomie.barVorhanden}/>
                    </ul>
                    <H5 label="gastronomie_fruehstueck"/>
                    <ul>
                        <CRow label={"gastronomie_fruehstueckOrt"} value={gastronomie.fruehstueckOrt}/>
                        <CRow label={"gastronomie_fruehstueck"} value={gastronomie.fruehstueck}/>
                        <CRow label={"gastronomie_fruehstueckWieIndividualgaeste"} value={gastronomie.fruehstueckWieIndividualgaeste}/>
                        <CRow label={"gastronomie_aussenbewirtschaftungMoeglich"} value={gastronomie.aussenbewirtschaftungMoeglich}/>
                    </ul>
                    <H5 label="gastronomie_fruehstueckszeiten"/>
                    <ul>
                        <CRow label={"oeffnungszeiten"} type="time"
                            hourFrom={gastronomie.fruehstueckszeiten.von.stunde}
                            minuteFrom={gastronomie.fruehstueckszeiten.von.minute}
                            hourTo={gastronomie.fruehstueckszeiten.bis.stunde}
                            minuteTo={gastronomie.fruehstueckszeiten.bis.minute}/>
                    </ul>
                    {
                        gastronomie.abweichendeFruehstueckszeitenAmWochenende.von.stunde ||
                        gastronomie.abweichendeFruehstueckszeitenAmWochenende.bis.stunde
                            ? <>
                                <H5 label="gastronomie_abweichendeFruehstueckszeitenAmWochenende"/>
                                <ul>
                                    <CRow label={"oeffnungszeiten"} type="time"
                                        hourFrom={gastronomie.abweichendeFruehstueckszeitenAmWochenende.von.stunde}
                                        minuteFrom={gastronomie.abweichendeFruehstueckszeitenAmWochenende.von.minute}
                                        hourTo={gastronomie.abweichendeFruehstueckszeitenAmWochenende.bis.stunde}
                                        minuteTo={gastronomie.abweichendeFruehstueckszeitenAmWochenende.bis.minute}/>
                                </ul>
                            </>
                            : ""
                    }
                    <H5 label="gastronomie_abendessen"/>
                    <ul>
                        <CRow label={"gastronomie_abendessenOrt"} value={gastronomie.abendessenOrt}/>
                        <CRow label={"gastronomie_abendessenGruppen"} value={gastronomie.abendessenGruppen}/>
                        <CRow label={"gastronomie_abendessenAlsGruppe"} value={gastronomie.abendessenAlsGruppe}/>
                        <CRow label={"gastronomie_abendessenHauptgangZurWahl"} value={gastronomie.abendessenHauptgangZurWahl}/>
                        <CRow label={"gastronomie_abendessenSalatbuffet"} value={gastronomie.abendessenSalatbuffet}/>
                    </ul>
                    <H5 label="gastronomie_abendessenzeiten"/>
                    <ul>
                        <CRow label={"oeffnungszeiten"} type="time"
                            hourFrom={gastronomie.abendessenzeiten.von.stunde}
                            minuteFrom={gastronomie.abendessenzeiten.von.minute}
                            hourTo={gastronomie.abendessenzeiten.bis.stunde}
                            minuteTo={gastronomie.abendessenzeiten.bis.minute}/>
                    </ul>
                    {
                        gastronomie.barOeffnungszeiten.von.stunde || gastronomie.barOeffnungszeiten.bis.stunde
                            ? <>
                                <H5 label="gastronomie_barOeffnungszeiten"/>
                                <ul>
                                    <CRow label={"oeffnungszeiten"} type="time"
                                        hourFrom={gastronomie.barOeffnungszeiten.von.stunde}
                                        minuteFrom={gastronomie.barOeffnungszeiten.von.minute}
                                        hourTo={gastronomie.barOeffnungszeiten.bis.stunde}
                                        minuteTo={gastronomie.barOeffnungszeiten.bis.minute}/>
                                </ul>
                            </>
                            : ""
                    }
                    <H4 label={"hotelinfo_freizeit"}/>
                    <H5 label="freizeit_wellnessbereich"/>
                    <ul>
                        <CRow label={"freizeit_wellnessbereich_wellnessbereichVorhanden"} value={freizeit.wellnessbereich.wellnessbereichVorhanden}/>
                        <CRow label={"freizeit_wellnessbereich_nutzungGegenGebuehr"} value={freizeit.wellnessbereich.nutzungGegenGebuehr}/>
                    </ul>
                    <H5 label="freizeit_fitnessbereich"/>
                    <ul>
                        <CRow label={"freizeit_fitnessbereich_fitnessbereichVorhanden"} value={freizeit.fitnessbereich.fitnessbereichVorhanden}/>
                        <CRow label={"freizeit_fitnessbereich_nutzungGegenGebuehr"} value={freizeit.fitnessbereich.nutzungGegenGebuehr}/>
                    </ul>
                    <H5 label="freizeit_extras"/>
                    <ul>
                        <CRow label={"freizeit_skiraum"} value={freizeit.skiraum}/>
                        <CRow label={"freizeit_fahrradabstellraum"} value={freizeit.fahrradabstellraum}/>
                        <CRow label={"freizeit_motorradabstellplatz"} value={freizeit.motorradabstellplatz}/>
                        <CRow label={"freizeit_garten"} value={freizeit.garten}/>
                        <CRow label={"freizeit_massage"} value={freizeit.massage}/>
                        <CRow label={"freizeit_kurse"} value={freizeit.kurse}/>
                    </ul>
                </Col>
                <Col md={3} className="text-md-end">
                    <Button className="Button" variant="success" onClick={finalize} disabled={successDisabled}>
                        {i18n.t("button_abschliessenUndDatenUebermitteln")}
                    </Button>
                    {messageToRender}
                </Col>
            </Row>
            <Button className="Button" variant="success" onClick={finalize} disabled={successDisabled}>
                {i18n.t("button_abschliessenUndDatenUebermitteln")}
            </Button>
            {messageToRender}
        </>
    );
};
export default Finalize;
