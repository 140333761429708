
function getLandeskategorienArray () {
    const array = [];
    array.push({
        name: "allgemein_landeskategorie_-- keine --",
        value: ""
    });
    array.push({
        name: "allgemein_landeskategorie_einStern",
        value: "einStern"
    });
    array.push({
        name: "allgemein_landeskategorie_zweiSterne",
        value: "zweiSterne"
    });
    array.push({
        name: "allgemein_landeskategorie_dreiSterne",
        value: "dreiSterne"
    });
    array.push({
        name: "allgemein_landeskategorie_vierSterne",
        value: "vierSterne"
    });
    array.push({
        name: "allgemein_landeskategorie_fuenfSterne",
        value: "fuenfSterne"
    });
    return array;
}

function getLobbyArray () {
    const array = [];
    array.push({
        name: "allgemein_lobby_klein",
        value: "klein"
    });
    array.push({
        name: "allgemein_lobby_mittel",
        value: "mittel"
    });
    array.push({
        name: "allgemein_lobby_gross",
        value: "gross"
    });
    return array;
}

function getRezeptionArray () {
    const array = [];
    array.push({
        name: "allgemein_reception_teilzeit",
        value: "teilzeit"
    });
    array.push({
        name: "allgemein_reception_vollzeit",
        value: "vollzeit"
    });
    return array;
}

module.exports.getLandeskategorienArray = getLandeskategorienArray;
module.exports.getLobbyArray = getLobbyArray;
module.exports.getRezeptionArray = getRezeptionArray;
