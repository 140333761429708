import AlertMessage from "../elements/AlertMessage";
import { SaveAndContinue } from "../elements/buttons/SaveAndContinue";
import FinalizeTabMessage from "../elements/finalize/FinalizeMessage";
import { Checkbox } from "../inputfields/Checkbox";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { H4 } from "../elements/H4";
import { H5 } from "../elements/H5";
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import { useHotelinfoData } from "../contextProvider/HotelinfoContext";
import { useTabContext, useUpdateTabData } from "../contextProvider/TabContext";

export function Freizeit (props) {
    const hotelinfoData = useHotelinfoData();
    const freizeit = hotelinfoData.hotelinfo.freizeit;
    const tabContext = useTabContext();
    const updateTabContextData = useUpdateTabData();
    const [validated, setValidated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showFinalizeAlert, setShowFinalizeAlert] = useState(false);
    const [wellnessGegenGebuehrDisabled, setWellnessGegenGebuehrDisabled] =
        useState(!freizeit.wellnessbereich.wellnessbereichVorhanden);
    const [wellnessGegenGebuehr, setWellnessGegenGebuehr] =
        useState(freizeit.wellnessbereich.nutzungGegenGebuehr);
    const [fitnessGegenGebuehrDisabled, setFitnessGegenGebuehrDisabled] =
        useState(!freizeit.fitnessbereich.fitnessbereichVorhanden);
    const [fitnessGegenGebuehr, setFitnessGegenGebuehr] =
        useState(freizeit.fitnessbereich.nutzungGegenGebuehr);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, process.env.REACT_APP_ALERT_TIMEOUT);
        } else {
            setShowAlert(false);
            tabContext.validation.freizeit = true;
            updateTabContextData(tabContext);
            props.handleSaveAndContinue("finalize");
        }
        setValidated(true);
        let showFinalizeAlert = false;
        for (const key in tabContext.validation) {
            if (!tabContext.validation[key]) {
                showFinalizeAlert = true;
            }
        }
        setShowFinalizeAlert(showFinalizeAlert);
        if (showFinalizeAlert) {
            setTimeout(() => {
                setShowFinalizeAlert(false);
            }, process.env.REACT_APP_ALERT_TIMEOUT);
        }
    };

    const handleFitnessbereichVorhanden = (checked) => {
        setFitnessGegenGebuehrDisabled(!checked);
        if (!checked) {
            setFitnessGegenGebuehr(false);
        }
    };

    const handleWellnessbereichVorhanden = (checked) => {
        setWellnessGegenGebuehrDisabled(!checked);
        if (!checked) {
            setWellnessGegenGebuehr(false);
        }
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <H4 label={props.category}/>
            <Row className="InputPadding">
                <Col md={3}>
                    <Checkbox label="freizeit_wellnessbereich_wellnessbereichVorhanden"
                        onCallback={handleWellnessbereichVorhanden}
                        initialValue={freizeit.wellnessbereich.wellnessbereichVorhanden}/>
                </Col>
                <Col md={3}>
                    <Checkbox label="freizeit_wellnessbereich_nutzungGegenGebuehr"
                        disabled={wellnessGegenGebuehrDisabled}
                        initialValue={fitnessGegenGebuehr}/>
                </Col>
            </Row>
            <Row className="InputPadding">
                <Col md={3}>
                    <Checkbox label="freizeit_fitnessbereich_fitnessbereichVorhanden"
                        onCallback={handleFitnessbereichVorhanden}
                        initialValue={freizeit.fitnessbereich.fitnessbereichVorhanden}/>
                </Col>
                <Col md={3}>
                    <Checkbox label="freizeit_fitnessbereich_nutzungGegenGebuehr"
                        disabled={fitnessGegenGebuehrDisabled}
                        initialValue={wellnessGegenGebuehr}/>
                </Col>
            </Row>
            <H5 label="freizeit_extras"/>
            <Row className="InputPadding">
                <Col md={3}>
                    <Checkbox label="freizeit_fahrradabstellraum" initialValue={freizeit.fahrradabstellraum}/>
                </Col>
                <Col md={3}>
                    <Checkbox label="freizeit_motorradabstellplatz" initialValue={freizeit.motorradabstellplatz}/>
                </Col>
                <Col md={3}>
                    <Checkbox label="freizeit_skiraum" initialValue={freizeit.skiraum}/>
                </Col>
            </Row>
            <Row className="InputPadding">
                <Col md={3}>
                    <Checkbox label="freizeit_garten" initialValue={freizeit.garten}/>
                </Col>
                <Col md={3}>
                    <Checkbox label="freizeit_massage" initialValue={freizeit.massage}/>
                </Col>
                <Col md={3}>
                    <Checkbox label="freizeit_kurse" initialValue={freizeit.kurse}/>
                </Col>
            </Row>
            <SaveAndContinue />
            <AlertMessage show={showAlert}/>
            <FinalizeTabMessage show={showFinalizeAlert}/>
        </Form>
    );
}
