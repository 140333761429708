import React, { useContext, useState } from "react";

const defaultContext = {
    finalizeActive: false,
    activeTab: "homepage",
    validation: {
        allgemein: false,
        anreise: false,
        freizeit: false,
        gastronomie: false,
        zimmer: false
    }
};

const TabContext = React.createContext(defaultContext);
const TabValidationContext = React.createContext();

export function useTabContext () {
    return useContext(TabContext);
}

export function useUpdateTabData () {
    return useContext(TabValidationContext);
}

export function TabContextProvider ({ children }) {
    const [tabContext, setTabContext] = useState(defaultContext);

    function updateTabContextData (context) {
        setTabContext(context);
    }

    return (
        <TabContext.Provider value={tabContext}>
            <TabValidationContext.Provider value={updateTabContextData}>
                {children}
            </TabValidationContext.Provider>
        </TabContext.Provider>
    );
};
