
function getAbendessenOrtArray () {
    const array = [];
    array.push({
        name: "gastronomie_abendessenOrt_imRestaurant",
        value: "imRestaurant"
    });
    array.push({
        name: "gastronomie_abendessenOrt_imKonferenzraum",
        value: "imKonferenzraum"
    });
    return array;
}

function getAbendessenGruppenBekommenArray () {
    const array = [];
    array.push({
        name: "gastronomie_abendessenGruppen_3GangMenu",
        value: "3GangMenu"
    });
    array.push({
        name: "gastronomie_abendessenGruppen_4GangMenu",
        value: "4GangMenu"
    });
    array.push({
        name: "gastronomie_abendessenGruppen_buffet",
        value: "buffet"
    });
    return array;
}
function getFruehstueckOrtArray () {
    const array = [];
    array.push({
        name: "gastronomie_fruehstueckOrt_imRestaurant",
        value: "imRestaurant"
    });
    array.push({
        name: "gastronomie_fruehstueckOrt_imKonferenzraum",
        value: "imKonferenzraum"
    });
    return array;
}

function getFruehstueckGruppenBekommenArray () {
    const array = [];
    array.push({
        name: "gastronomie_fruehstueck_kaltesBuffet",
        value: "kaltesBuffet"
    });
    array.push({
        name: "gastronomie_fruehstueck_kaltesWarmesBuffet",
        value: "kaltesWarmesBuffet"
    });
    array.push({
        name: "gastronomie_fruehstueck_kontinentalesFruehstueck",
        value: "kontinentalesFruehstueck"
    });
    return array;
}

module.exports.getAbendessenOrtArray = getAbendessenOrtArray;
module.exports.getAbendessenGruppenBekommenArray = getAbendessenGruppenBekommenArray;
module.exports.getFruehstueckOrtArray = getFruehstueckOrtArray;
module.exports.getFruehstueckGruppenBekommenArray = getFruehstueckGruppenBekommenArray;
