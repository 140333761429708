import React from "react";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";

function ErrorMessage (props) {
    const { i18n } = useTranslation();
    return (
        <Alert show={props.show} variant="danger">
            <Alert.Heading>{i18n.t("unerwarteter_fehler")}</Alert.Heading>
            <p>{i18n.t("unerwarteter_fehler_text")}</p>
            <p>{props.error}</p>
        </Alert>
    );
}

export default ErrorMessage;
