import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useHotelinfoData, useUpdateHotelinfoData } from "../contextProvider/HotelinfoContext";

export function SelectBox (props) {
    const hotelinfoData = useHotelinfoData();
    const updateHotelinfoData = useUpdateHotelinfoData();
    const { i18n } = useTranslation();
    const name = props.name ? props.name : props.label;
    const forEachOptions = [];
    const getOptions = (options) => {
        options.forEach(obj => {
            forEachOptions.push(
                <option
                    key={obj.name}
                    value={obj.value}>
                    {i18n.t(obj.name)}
                </option>);
        });
        return forEachOptions;
    };

    function handleChange (event) {
        const { name, value } = event.target;
        setVal(value);
        const keyArray = name.split("_");
        if (keyArray.length) {
            if (keyArray.length === 2) {
                hotelinfoData.hotelinfo[keyArray[0]][keyArray[1]] = value;
            } else if (keyArray.length === 3) {
                hotelinfoData.hotelinfo[keyArray[0]][keyArray[1]][keyArray[2]] = value;
            }
            updateHotelinfoData(hotelinfoData);
        }
    }

    const [val, setVal] = useState(() => {
        return props.initialValue === null ? "" : props.initialValue;
    });

    return (
        <>
            <Form.Label>{i18n.t(props.label)}</Form.Label>
            <Form.Select
                key={name}
                id={name}
                name={name}
                aria-label={props.label}
                onChange={handleChange}
                value={val}>
                {getOptions(props.options)}
            </Form.Select>
        </>
    );
}
